const setIsSettingsVisible = ({ state }, value) => {
      state.isSettingsVisible = value;
};
const setSelectedMenuItem = ({ state }, menuItem) => {
    state.selectedMenuItem = menuItem;
};

const setThemeMode = ({ state }, themeMode) => {
  state.themeMode = themeMode;
  localStorage.setItem('themeMode', themeMode); // Current solution is temporary
  // TODO: Design proper solution
  // to sync state (or a field of state) with localStorage
};

const setEditorMode = ({ state }, editorMode) => {
  state.editorMode = editorMode;
};

const showNotification = ({ state }, { message, variant = 'info', opt = {} }) => {
  console.log("Showing notification:", { message, variant, opt }); // Add this line
  state.notifications = {
    isActive: true,
    message,
    variant,
    opt,
  };
};

const hideNotification = ({ state }) => {
  state.notifications.isActive = false;
};

const setSelectedLanguageId = type => ({ state }, id) => {
  state[type].selectedLanguageId = id;
};

const editor = {
  setSelectedLanguageId: setSelectedLanguageId('editor'),
  setOptions({ state }, options) {
    state.editor.options = options;
  },
  setMonacoTheme({ state }, theme) {
    state.monacoTheme = theme;
  },
};

const diffEditor = {
  setSelectedLanguageId: setSelectedLanguageId('diffEditor'),
};

export const defineAndSetThemeBackground = async ({ state, actions, effects }, theme) => {
  const themeData = await effects.defineTheme(theme);
  actions.setThemeBackground(themeData.colors['editor.background']);
};

const setThemeBackground = ({ state }, color) => {
  state.themeBackground = color;
};

const setFontColor = ({ state }, color) => {
  state.fontColor = color;
};

function executeCode (code) {
 // we are provided with code value
  const inputData = { message: { code: code, input_values: null } }; // Prepare the request payload

  fetch("http://localhost:8000/courses/api/message/", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(inputData),
  })
      .then(response => response.json())
      .then(data => console.log(data.message)); // Log the response message from Django
}

const setIsEditorReady = ({ state }, isReady) => {
  state.isEditorReady = isReady;
};

// ... (other actions remain the same)

const setEditorTab = ({ state }, tabIndex) => {
  console.log('setEditorTab action called with:', tabIndex);
  state.editorTab = tabIndex;
};

const setOpenedFile = ({ state }, file) => {
    if (JSON.stringify(state.openedFile) !== JSON.stringify(file)) {
        state.openedFile = {
            ...state.openedFile,
            ...file
        };
    }
};

const setLastOpenedFile = ({ state }, file) => {
    if (JSON.stringify(state.lastOpenedFile) !== JSON.stringify(file)) {
        state.lastOpenedFile = {
            ...state.lastOpenedFile,
            ...file
        };
    }
};

const setRecentFile = ({ state }, file) => {
    if (JSON.stringify(state.recentFile) !== JSON.stringify(file)) {
        state.recentFile = {
            ...state.recentFile,
            ...file
        };
    }
};

const setLastPlaygroundFile = ({ state }, file) => {
    if (JSON.stringify(state.lastPlaygroundFile) !== JSON.stringify(file)) {
        state.lastPlaygroundFile = {
            ...state.lastPlaygroundFile,
            ...file
        };
    }
};

const updateOpenedFilePath = ({state}, path) => {
    if (state.openedFilePath) {
        state.openedFilePath = path;
    }
};

const setFilesInDirectory = (files) => ({
  type: 'SET_FILES_IN_DIRECTORY',
  payload: files
});

// This action might be useful if you want to update the content of a file in your state
const updateFileContent = (fileId, content) => ({
  type: 'UPDATE_FILE_CONTENT',
  payload: { fileId, content }
});

const setNewCode = ({state}, code) => {
  state.newCode = code;
};


const setUiContext = ({state}, uiContext) => {
  state.uiContext = uiContext;  // This should be uiContext, not newCode
};

const setUser = ({ state }, user) => {
  state.user = user;
};

const setHasOpenedFile = ({ state }, value) => {
    state.hasOpenedFile = value;
};

const resetFileSystemState = ({ state }) => {
    state.fileSystem = null;
    state.openedFile = {
        name: 'test_component.js',
        path: 'Root/test_component.js',
        content: '',
    };
    state.filesInDirectory = [
        {
            name: 'test_component.js',
            path: 'Root/test_component.js',
        }
    ];
    state.selectedFile = null;
    state.currentFilePath = 'Root/test_component.js';
    state.currentFileName = 'test_component.js';
    state.editorContent = '';
    state.newCode = '';
};
const setShowOverlay = ({ state }, value) => {
    state.showOverlay = value;
};



export {
    setHasOpenedFile,
    setRecentFile,
    setLastPlaygroundFile,
    setLastOpenedFile,
    setShowOverlay,
    resetFileSystemState,
    updateOpenedFilePath,
    updateFileContent,
    executeCode,
    hideNotification,
    setNewCode,
    setUiContext,
    setThemeMode,
    setEditorMode,
    setIsEditorReady,
    showNotification,
    setIsSettingsVisible,
    setFontColor,
    setThemeBackground,
    editor,
    diffEditor,
    setUser,
    setEditorTab,
    setOpenedFile,  // Add this new action to the exports
    setFilesInDirectory,
    setSelectedMenuItem,
};